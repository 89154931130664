
.masonry-grid {
    margin: calc(var(--grid-gutter) * -1);
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}
.masonry-grid-item {
    width: 50%;
    padding: var(--grid-gutter);
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .masonry-grid-item {
        width: 33.3333%;
    }
}
@media (min-width: 1024px) {
    .masonry-grid {
        margin: calc(var(--grid-gutter-lg) * -1);
    }
    .masonry-grid-item {
        padding: var(--grid-gutter-lg);
    }
}
@media (min-width: 1200px) {
}
