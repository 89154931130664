
.project-card{
    display: block;
    &__thumb{
        position: relative;
        overflow: hidden;
    }
    &__btn{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1.4);
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s ease-out;
    }    
    &--link .project-card__thumb::after{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        opacity: 0;
        visibility: hidden;
        z-index: 1;
        transition: 0.2s ease-out;
    }
}
.image-wrap{
    position: relative;
    >img{
        display: block;
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .masonry-grid-item {
        width: 33.3333%;
    }
}
@media (min-width: 1024px) {
    .project-card{
        &:hover{
            .project-card__thumb::after{
                opacity: 1;
                visibility: visible;
                transition: 0.2s ease-out;
            }
            .project-card__btn{
                opacity: 1;
                visibility: visible;
                transform: translate(-50%, -50%) scale(1);
                transition: 0.2s 0.2s ease-out;
            }
        }
    }
}
@media (min-width: 1200px) {
}
