
.business-card{
    position: relative;
    overflow: hidden;
    background-color: transparent;
    &__contents{
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .7);
    }
    &__image{
        transform: scale(1);
        transition: 0.25s ease-out;
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .business-card:hover{
        .business-card__image{
            transform: scale(1.2);
        }
    }
}
@media (min-width:1200px){
}
