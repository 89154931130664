
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .v-btn.grey--text.text--lighten-1:hover{
        color: var(--v-primary-base) !important;
    }
}
@media (min-width:1200px){
}
