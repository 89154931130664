
// btn-top-primary
.btn-top-primary {
    bottom: var(--btn-top-position-bottom) !important;
    right: var(--btn-top-position-right) !important;
    transition: all ease-out 0.2s;
    box-shadow: none !important;
    will-change: unset !important;
    z-index: 10;
    ::v-deep{
        .v-btn__content{
            flex-direction: column;
        }
    }
    &__line{
        width: 1px;
        height: 20px;
        background-color: var(--v-grey-darken4);
        display: block;
        margin: 0 auto;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .btn-top-primary {
        z-index: 101;
        &.grey--text.text--darken-4{
            color: #fff !important;
        }
        &__line{
            height: 40px;
            background-color: rgba(255, 255, 255, 0.6);
        }
    }
}
