
.u-btn-custom{
    &.v-btn.v-btn--icon.v-size--default.v-btn--outlined{
        position: relative;
        border-color: transparent !important;
        width: 100px !important;
        height: 100px !important;
        ::v-deep{
            .v-btn__content{
                width: 100%;
                height: 100%;
                padding: 10px;
            }
        }
        &::before{
            display: none;
        }
    }
    &__inner{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-radius: 50%;
        transition: 0.15s ease-out;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .u-btn-custom{
        &.v-btn.v-btn--icon.v-size--default.v-btn--outlined{
            width: 180px !important;
            height: 180px !important;
            ::v-deep{
                .v-btn__content{
                    padding: 20px;
                }
            }
        }
    }
}
@media (min-width:1024px){
    .u-btn-custom:hover{
        .u-btn-custom__inner{
            background-color: var(--v-primary-base);
            color: #fff !important;
            border-color: var(--v-primary-base) !important;
        }
    }
}
@media (min-width:1200px){
}

