
.story-card{
    overflow: hidden;
    &__contents{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
        background-image: linear-gradient(to top, rgba(0, 0, 0, .8), transparent);
        display: flex;
        align-items: flex-end;
    }
    &__image{
        transform: scale(1);
        transition: 0.25s ease-out;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .story-card:hover{
        .story-card__image{
            transform: scale(1.2);
        }
    }
}
@media (min-width:1200px){
}
