
.value-contents{
    position: relative;
    z-index: 1;
    .icon-rounded-object{
        top: -90px;
        left: calc(33.33333% - 78px);
        z-index: 5;
    }
    &__slide{
        position: relative;
    }
    &__left{
        margin-top: -123px;
        &__inner{
            padding: 0 16px;
            width: 100%;
            max-width: 100%;
        }
        .line{
            display: block;
            width: 1px;
            height: 28px;
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
    &__right{
        &__inner{
            position: relative;
        }
        &__image{
            position: relative;
            width: 100%;
            height: 0;
            padding-top: calc((634 / 952) * 100%);
            &-wrap{
                width: 100%;
            }
            &__inner{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
            }
        }
    }
    &__btn-group{
        .line{
            display: block;
            width: 1px;
            height: 12px;
            background-color: rgba(0, 0, 0, 0.2);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .value-contents{
        position: relative;
        &__left{
            margin-top: -154px;
            &__inner{
                padding: 0;
                width: 100%;
                max-width: 100%;
            }
            .line{
                height: 46px;
            }
        }
    }
}
@media (min-width:1024px){
    .value-contents{
        &__left{
            margin-top: 0;
            position: absolute;
            left: -12px;
            top: 0;
            z-index: 1;
            height: 100%;
            display: flex;
            align-items: flex-end;
            &__inner{
                padding-bottom: 40px;
            }
        }
        &__btn-group{
            position: absolute;
            left: 0;
            bottom: -12px;
            z-index: 2;
        }
    }
}
@media (min-width:1200px){
}
@media (min-width:1440px){
    .value-contents{
        &__left{
            display: block;
            &__inner{
                padding-top: 238px;
                padding-bottom: 0;
                min-height: 0;
                margin-top: 0;
            }
        }
    }
}
@media (min-width:1544px){
    .value-contents{
        ::v-deep{
            .swiper-container{
                overflow: visible;
            }
        }
        &__right{
            position: relative;
            left: -33.3333333333%;
            transition: 0.5s ease-out;
            &__inner{
                height: 634px;
                display: flex;
                align-items: flex-end;
            }
            &__image{
                &::after{
                    content: "";
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.7);
                    opacity: 1;
                    visibility: visible;
                    transition: 0.5s ease-out;
                }
                &-wrap{
                    max-width: 600px;
                    max-height: 400px;
                    transition: 0.5s ease-out;
                }
            }
        }
        &__left{
            &__card{
                opacity: 0;
                visibility: hidden;
                transition: 0.25s ease-out;
            }
        }
        .swiper-slide-active{
            .value-contents__left{
                &__card{
                    opacity: 1;
                    visibility: visible;
                    transition: 0.25s 0.5s ease-out;
                }
            }
            .value-contents__right{
                left: 0;
            }
            .value-contents__right__image::after{
                opacity: 0;
                visibility: hidden;
            }
            .value-contents__right__image-wrap{
                max-width: 100%;
                max-height: 100%;                
            }
        }
    }
}

