
.introduction-contents{
    position: relative;
    max-width: 484px;
    margin: 0 auto;
    &__image{
        width: 100%;
        height: 0;
        padding-top: calc((696 / 464) * 100%);
        position: relative;
        &__inner{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            top: 0;
            left: 0;
            opacity: 1;
            visibility: visible;
            transition: 0.15s ease-out;
        }
    }
    &__item{
        display: block;
        position: relative;
        &__card{
            max-width: calc(100% - 36px);
            position: absolute;
            bottom: 0;
            left: 16px;
            width: 100%;
            opacity: 0;
            visibility: visible;
            transition: 0.15s ease-out;
        }
    }
    ::v-deep{
        .swiper-container{
            overflow: visible !important;
        }
        .swiper-slide:not(.swiper-slide-active){
            cursor: default;
            pointer-events: none;
        }
        .swiper-slide.swiper-slide-active{
            .introduction-contents__image::after{
                opacity: 0;
                visibility: visible;
            }
            .introduction-contents__item__card{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &__btn-group{
        .line{
            display: block;
            width: 1px;
            height: 12px;
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .introduction-contents{
        &__item{
            &__card{
                left: -30px;
            }
            &:hover{
                .introduction-contents__item__card{
                    &.primary{
                        background-color: var(--v-primary-darken4) !important;
                    }
                }
            }
        }
        .swiper-btn{
            position: absolute;
            top: 50%;
            z-index: 5;
            &-prev{
                left: -40px;
                transform: translate(-100%, -50%);
            }
            &-next{
                right: -40px;
                transform: translate(100%, -50%);
            }
        }
    }
}
@media (min-width:1024px){
    .introduction-contents{
        &__item{
            &__card{
                left: -40px;
            }
            &:hover{
                .introduction-contents__item__card{
                    &.primary{
                        background-color: var(--v-primary-darken4) !important;
                    }
                }
            }
        }
        .swiper-btn{
            &-prev{
                left: -80px;
            }
            &-next{
                right: -80px;
            }
        }
    }
}
@media (min-width:1200px){
}

