
.scroll-ani{
    position: absolute;
    left: 16px;
    bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__dot{
        &__dot,
        &::before,
        &::after{
            display: block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #fff;
            opacity: 0.3;
            animation: scroll 0.75s ease-out infinite;
        }
        &::before,
        &::after{
            content: "";
        }
        &::before{
            margin-bottom: 8px;
        }
        &__dot{
            animation-delay: 0.25s;
        }
        &::after{
            margin-top: 8px;
            animation-delay: 0.5s;
        }
    }
}
@keyframes scroll {
    0%{
        opacity: 0.3;
    }
    30%{
        opacity: 1;
    }
    70%{
        opacity: 1;
    }
    100%{
        opacity: 0.3;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .scroll-ani{
        left: 60px;
        bottom: 60px;
        &__dot{
            &::before{
                margin-bottom: 16px;
            }
            &::after{
                margin-top: 16px;
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

