
// main-visual
.main-visual {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: keep-all;
    position: relative;
    &__video{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
        display: block;
        overflow: hidden;
        &[poster]{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__inner{
        position: relative;
        z-index: 1;
    }
    &::after{
        content: "";
        display: block;
        position: absolute;
        z-index: 2;
        width: 50px;
        height: 50px;
        bottom: 0;
        right: 0;
        background-image: url(/images/main/main-visual-bg.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    &__txt-ani{
        width: 290px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        span{
            display: block;
            transform: translateY(100%);
            opacity: 0;
            visibility: hidden;
            animation: fadeUp 0.3s ease-out forwards;
            &:nth-child(1){
                animation-delay: 0.5s;
            }
            &:nth-child(2){
                animation-delay: 0.55s;
            }
            &:nth-child(3){
                animation-delay: 0.6s;
            }
            &:nth-child(4){
                animation-delay: 0.65s;
            }
            &:nth-child(5){
                animation-delay: 0.7s;
            }
            &:nth-child(6){
                animation-delay: 0.75s;
            }
            &:nth-child(7){
                animation-delay: 0.8s;
            }
            &:nth-child(8){
                animation-delay: 0.85s;
            }
            &:nth-child(9){
                animation-delay: 0.9s;
            }
            &:nth-child(10){
                animation-delay: 0.95s;
            }
            &:nth-child(11){
                animation-delay: 1.0s;
            }
            &:nth-child(12){
                animation-delay: 1.05s;
            }
            &:nth-child(13){
                animation-delay: 1.1s;
            }
            &:nth-child(14){
                animation-delay: 1.15s;
            }
            &:nth-child(15){
                animation-delay: 1.2s;
            }
        }
    }
    &__line{
        border-color: rgba(255, 255, 255, 0.2) !important;
        width: 100vw;
        max-width: 100vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%) scaleX(0);
        animation: scaleX 0.7s 1.2s ease-out forwards;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    // main-visual
    .main-visual {
        &::after{
            width: 80px;
            height: 80px;
        }
        &__txt-ani{
            width: 430px;
            padding: 0 12px;
        }
    }
}
@media (min-width: 1024px) {
    // main-visual
    .main-visual {
        &::after{
            width: 100px;
            height: 100px;
        }
        &__txt-ani{
            width: 544px;
        }
    }
}
@media (min-width: 1200px) {
}

@keyframes fadeUp{
    0%{
        transform: translateY(100%);
        opacity: 0;
        visibility: hidden;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}
@keyframes scaleX{
    0%{
        transform: translateX(-50%) scaleX(0);
    }
    100%{
        transform: translateX(-50%) scaleX(1);
    }
}
