
.main-section{
    &--business{
        background-image: url(/images/main/business-bg.jpg);
        .business-bg{
            position: absolute;
            left: 0;
            &--1{
                top: 0;
            }
            &--2{
                top: 0;
            }
            &--3{
                bottom: 0;
            }
        }
    }
    &--value{
        position: relative;
        &::before{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 60%;
            background-color: var(--v-grey-lighten5);
            top: 0;
            left: 0;
        }
        .container{
            position: relative;
        }
    }
    &--introduction{
        background-image: url(/images/main/introduction-bg.jpg);
    }
    &--stroy{
        background-image: url(/images/main/stroy-bg.jpg);
        .stroy-bg{
            position: absolute;
            left: 0;
            &--1{
                bottom: 0;
            }
        }
    }
    &--contact{
        overflow: hidden;
        background-image: url(/images/main/contact-bg.jpg);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .main-section{
        &--value{
            &::before{
                height: 596px;
            }
        }
    }
}
@media (min-width:1200px){
}
@media (min-width:1440px){
    .main-section{
        &--value{
            &__title{
                position: absolute;
                left: 12px;
                top: 0;
                z-index: 2;
            }
        }
    }
}

